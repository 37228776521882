import jQuery from "jquery";
const $ = jQuery; // ドルマークに参照を代入(慣習的な $ を使うため)

import './customize';
import './layout';
import './content';
import './forms';
import './components';
import './helpers';
import './utilities';
import './extened';
import './pages';

// import文を使ってSassファイルを読み込む
import "../scss/style.scss";
