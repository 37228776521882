import jQuery from "jquery";
const $ = jQuery; // ドルマークに参照を代入(慣習的な $ を使うため)


/* trにリンク追加できるようにする */
$(function($) {
  $('tbody tr[data-href]').addClass('clickable').click( function() {
    window.location = $(this).attr('data-href');
  }).find('a').hover( function() {
    $(this).parents('tr').unbind('click');
  }, function() {
    $(this).parents('tr').click( function() {
      window.location = $(this).attr('data-href');
    });
  });
});


/* score circle にカラーを追加 */
var s_trigger = document.getElementsByClassName("score-circle");
var s_triggers = Array.from(s_trigger);

s_triggers.forEach(function(target) {
  var datascore = Number($(target).data('score'));
  if(datascore > 75){
    $(target).addClass('score-level-4');
  } else if(datascore > 50){
    $(target).addClass('score-level-3');
  } else if(datascore > 25){
    $(target).addClass('score-level-2');
  } else if(datascore > 0){
    $(target).addClass('score-level-1');
  }
});
