import jQuery from "jquery";
const $ = jQuery; // ドルマークに参照を代入(慣習的な $ を使うため)


/*
 * $ npm i object-fit-images
 * This file is use object-fit-images
 * IEをobjectFitに対応させるためのライブラリ
 * https://www.npmjs.com/package/object-fit-images
 *
*/
import objectFitImages from 'object-fit-images'
objectFitImages('.objectfit');


/*
 * PostCSS Viewport Height Correction
 * https://github.com/Faisal-Manzer/postcss-viewport-height-correction
 * height: 100vh; -> height: calc(var(--vh, 1vh) * 100);
*/
var customViewportCorrectionVariable = 'vh';
function setViewportProperty(doc) {
  var prevClientHeight;
  var customVar = '--' + ( customViewportCorrectionVariable || 'vh' );
  function handleResize() {
    var clientHeight = doc.clientHeight;
    if (clientHeight === prevClientHeight) return;
    requestAnimationFrame(function updateViewportHeight(){
      doc.style.setProperty(customVar, (clientHeight * 0.01) + 'px');
      prevClientHeight = clientHeight;
    });
  }
  handleResize();
  return handleResize;
}
window.addEventListener('resize', setViewportProperty(document.documentElement));
